<template>
  <div>
    <BaseCenteredCardLanding
      cardColor="rgba(17, 23, 22, 0.9)"
      :cardStyles="{
        boxShadow:
          'inset 0 1px 1px 0 #1de9b6, 0 50px 100px -20px rgb(0 188 212 / 12%),0 30px 60px -30px rgb(33 150 243 / 6%)',
      }"
    >
      <template #cardSubheader
        ><p class="mt-6 text-h6 text-center teal--text text--accent-3">
          Informed Decisions.
          <span class="teal--text text--accent-2">Robust Planning. </span>
          <span class="teal--text text--accent-1">More Control.</span>
        </p></template
      >

      <template #primaryColumn>
        <h3 class="mb-5">Request Access</h3>
        <RequestAccessForm />
      </template>

      <template #secondaryColumn>
        <div>
          <h3 class="mb-5">Questions?</h3>
          <p>
            Call:
            <a
              href="tel:8337526427"
              class="text-decoration-none teal--text text--accent-2"
              >1-833-PLANGAP</a
            >
          </p>
          <p>
            Email:
            <a
              href="mailto:insights@plangap.com?subject=Insights Request Access"
              class="text-decoration-none teal--text text--accent-2"
              >insights@plangap.com</a
            >
          </p>
          <!-- <p>
          <router-link
            to="/access"
            class="text-decoration-none teal--text text--accent-2"
            >Check out the FAQ</router-link
          >
        </p> -->
        </div>

        <v-spacer></v-spacer>
        <p class="text-caption">
          Already have an account?
          <router-link
            to="/login"
            class="text-decoration-none text-no-wrap teal--text text--accent-2"
            >Login
            <v-icon small color="teal accent-2"
              >mdi-arrow-right</v-icon
            ></router-link
          >
        </p>
      </template>

      <template #backgroundGraphic>
        <div class="d-none d-md-block">
          <AnimatedBarChart />
        </div>
      </template>
    </BaseCenteredCardLanding>
  </div>
</template>

<script>
import AnimatedBarChart from "@/components/AnimatedBarChart";
import BaseCenteredCardLanding from "@/components/BaseCenteredCardLanding";
import RequestAccessForm from "@/components/RequestAccessForm";

export default {
  props: {
    cardColor: {
      type: String,
      required: false,
    },
    bgGraphicPath: {
      type: String,
      required: false,
    },
  },
  components: {
    AnimatedBarChart,
    BaseCenteredCardLanding,
    RequestAccessForm,
  },
};
</script>

<style lang="scss" scoped>
.pgi-asset-card {
  position: relative;
  z-index: 1;
  background: #111716;
  box-shadow: inset 0 1px 1px 0 #1de9b6, 0 50px 100px -20px rgb(0 188 212 / 12%),
    0 30px 60px -30px rgb(33 150 243 / 6%) !important;
}

.pgi-access-bg {
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  opacity: 0.05;
}
</style>
