<template>
  <svg
    class="pgi-svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 683.63 260.71"
  >
    <g opacity="0.7">
      <rect
        y="133.25"
        width="11.59"
        height="127.46"
        fill="#32e8b7"
        opacity="0.45"
      />
      <rect x="17.38" y="46.35" width="11.59" height="214.36" fill="#32e8b7" />
      <rect
        x="34.76"
        y="92.7"
        width="11.59"
        height="168.01"
        fill="#32e8b7"
        opacity="0.45"
      />
      <rect x="63.73" y="179.6" width="11.59" height="81.11" fill="#32e8b7" />
      <rect x="81.12" y="75.32" width="11.59" height="185.39" fill="#32e8b7" />
      <rect
        x="98.5"
        y="11.59"
        width="11.59"
        height="249.12"
        fill="#32e8b7"
        opacity="0.45"
      />
      <rect
        x="127.44"
        y="104.28"
        width="11.59"
        height="156.42"
        fill="#32e8b7"
        opacity="0.45"
      />
      <rect
        x="144.82"
        y="156.42"
        width="11.59"
        height="104.28"
        fill="#32e8b7"
      />
      <rect
        x="162.2"
        y="52.14"
        width="11.59"
        height="208.57"
        fill="#32e8b7"
        opacity="0.45"
      />
      <rect x="191.19" y="86.9" width="11.59" height="173.8" fill="#32e8b7" />
      <rect
        x="208.57"
        y="121.66"
        width="11.59"
        height="139.04"
        fill="#32e8b7"
      />
      <rect
        x="225.95"
        y="173.8"
        width="11.59"
        height="86.9"
        fill="#32e8b7"
        opacity="0.45"
      />
      <rect
        x="254.92"
        y="156.42"
        width="11.59"
        height="104.28"
        fill="#32e8b7"
      />
      <rect x="272.3" y="196.98" width="11.59" height="63.73" fill="#32e8b7" />
      <rect
        x="289.68"
        y="81.11"
        width="11.59"
        height="179.6"
        fill="#32e8b7"
        opacity="0.45"
      />
      <rect x="318.63" y="46.35" width="11.59" height="214.36" fill="#32e8b7" />
      <rect
        x="336.02"
        y="104.28"
        width="11.59"
        height="156.42"
        fill="#32e8b7"
        opacity="0.45"
      />
      <rect x="353.4" y="179.6" width="11.59" height="81.11" fill="#32e8b7" />
      <rect
        x="382.37"
        y="150.63"
        width="11.59"
        height="110.08"
        fill="#32e8b7"
      />
      <rect
        x="399.75"
        y="11.59"
        width="11.59"
        height="249.12"
        fill="#32e8b7"
        opacity="0.45"
      />
      <rect
        x="417.13"
        y="92.7"
        width="11.59"
        height="168.01"
        fill="#32e8b7"
        opacity="0.45"
      />
      <rect x="446.09" y="75.32" width="11.59" height="185.39" fill="#32e8b7" />
      <rect
        x="463.48"
        y="133.25"
        width="11.59"
        height="127.46"
        fill="#32e8b7"
        opacity="0.45"
      />
      <rect x="480.86" y="40.55" width="11.59" height="220.15" fill="#32e8b7" />
      <rect x="509.83" y="86.9" width="11.59" height="173.8" fill="#32e8b7" />
      <rect x="527.21" y="202.77" width="11.59" height="57.93" fill="#32e8b7" />
      <rect
        x="544.59"
        y="162.22"
        width="11.59"
        height="98.49"
        fill="#32e8b7"
        opacity="0.45"
      />
      <rect
        x="573.56"
        y="115.87"
        width="11.59"
        height="144.84"
        fill="#32e8b7"
      />
      <rect
        x="590.94"
        y="57.93"
        width="11.59"
        height="202.77"
        fill="#32e8b7"
        opacity="0.45"
      />
      <rect
        x="608.32"
        y="156.42"
        width="11.59"
        height="104.28"
        fill="#32e8b7"
      />
      <rect
        x="637.29"
        y="110.08"
        width="11.59"
        height="150.63"
        fill="#32e8b7"
        opacity="0.45"
      />
      <rect x="654.67" width="11.59" height="260.71" fill="#32e8b7" />
      <rect x="672.05" y="81.11" width="11.59" height="179.6" fill="#32e8b7" />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
svg {
  vertical-align: bottom;
}

$n-bars: 60;
rect {
  animation: swave 1s ease-in-out infinite alternate;
  transform-origin: bottom;
  transform: scale3d(1, 0.55, 1) translateZ(0);
  will-change: transform;

  @for $i from 0 to $n-bars {
    &:nth-child(#{$i + 1}) {
      animation-duration: 0.55s * random($n-bars) + 2s;
      animation-delay: $i * 0.1s;
    }
  }
}

@keyframes swave {
  to {
    transform: scale3d(1, 1, 1) translateZ(0);
  }
}
</style>
