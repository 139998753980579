import config from "../config";

async function sendTransactionalEmail(data) {
  //   const JWT = await store.dispatch("auth/getUserJWTToken");
  const JWT = "testjwt";

  const payload = {
    toEmail: data.toEmail,
    subject: data.subject,
    messageText: data.messageText,
    messageHtml: data.messageHtml
  };

  const response = await fetch(
    `${config.NETLIFY_FNC_API_URL}sendTransactionalEmailLambda`,
    {
      method: "POST",
      headers: { Authorization: `Bearer ${JWT}` },
      body: JSON.stringify(payload)
    }
  );
  console.log("RESPONSE IN SEND EMAIL", response);
  return response.json();
}

export { sendTransactionalEmail };
