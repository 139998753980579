<template>
  <validation-observer ref="observer" v-slot="{ invalid }" slim>
    <v-alert v-if="showAlert" dense outlined :type="alertType">
      {{ alertMessage }}
    </v-alert>
    <form v-if="!showAlert" @submit.prevent="handleRequestAccessSubmit">
      <!-- First Name -->
      <validation-provider
        v-slot="{ errors }"
        name="First Name"
        rules="required|max:50"
      >
        <v-text-field
          v-model="contact.firstName"
          color="teal"
          :counter="50"
          :error-messages="errors"
          label="First Name"
          required
        ></v-text-field>
      </validation-provider>
      <!-- Last Name -->
      <validation-provider
        v-slot="{ errors }"
        name="Last Name"
        rules="required|max:50"
      >
        <v-text-field
          v-model="contact.lastName"
          color="teal"
          :counter="50"
          :error-messages="errors"
          label="Last Name"
          required
        ></v-text-field>
      </validation-provider>
      <!-- Company Name -->
      <validation-provider
        v-slot="{ errors }"
        name="Company"
        rules="required|max:100"
      >
        <v-text-field
          v-model="contact.company"
          color="teal"
          :counter="100"
          :error-messages="errors"
          label="Company Name"
          required
        ></v-text-field>
      </validation-provider>
      <!-- Email -->
      <validation-provider
        v-slot="{ errors }"
        name="Email"
        rules="required|email"
      >
        <v-text-field
          v-model="contact.email"
          type="email"
          color="teal"
          :error-messages="errors"
          label="Email"
          required
        ></v-text-field>
      </validation-provider>
      <!-- Phone number -->
      <validation-provider
        v-slot="{ errors }"
        name="Phone"
        :rules="{
          required: true,
          regex: /^[2-9]\d{2}[2-9]\d{2}\d{4}$/
        }"
      >
        <v-text-field
          v-model="contact.phoneNumber"
          type="tel"
          color="teal"
          :error-messages="errors"
          label="Phone Number"
          required
        ></v-text-field>
      </validation-provider>

      <!-- Job Title -->
      <validation-provider
        v-slot="{ errors }"
        name="Job Title"
        rules="required|max:50"
      >
        <v-text-field
          v-model="contact.title"
          color="teal"
          :counter="50"
          :error-messages="errors"
          label="Job Title"
          required
        ></v-text-field>
      </validation-provider>
      <!-- Job Function -->
      <validation-provider
        v-slot="{ errors }"
        name="Job Function"
        rules="required"
      >
        <v-select
          v-model="contact.function"
          color="teal"
          :items="jobFunctions"
          :error-messages="errors"
          label="Job Function"
          data-vv-name="select"
          required
        ></v-select>
      </validation-provider>
      <div class="mt-8 text-center">
        <v-btn type="submit" :disabled="invalid" x-large color="teal darken-2">
          Submit Request
        </v-btn>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import {
  pingMailchimp,
  addMailchimp,
  interestsFromInterestCategoryMailchimp
} from "@/services/mailchimpServices.js";
import { sendTransactionalEmail } from "@/services/notificationServices.js";

function initialFormData() {
  return {
    firstName: "",
    lastName: "",
    company: "",
    email: "",
    phoneNumber: "",
    title: "",
    function: ""
  };
}

export default {
  name: "RequestAccessForm",
  data() {
    return {
      jobFunctions: [
        "Executive",
        "Product/Actuary",
        "Sales",
        "Operations",
        "Communications/Marketing",
        "Other"
      ],
      contact: new initialFormData(),
      showAlert: false,
      alertType: "info",
      alertMessage: "Something went wrong! Please try again."
    };
  },
  methods: {
    async ping() {
      const mcresult = await pingMailchimp();
      console.log("MC PING RESULT", mcresult);
    },
    async sendEmail(mcResultId) {
      if (this.contact && this.contact.email) {
        const data = {
          toEmail: "", // leave empty to use the default email set in server vars
          subject: "🎉 New PlanGap Insights Access Request!",
          messageText: `Someone has requested access to PlanGap Insights!\r\n\r\nName: ${this.contact.firstName} ${this.contact.lastName}\r\n\r\nEmail: ${this.contact.email}\r\n\r\nPhone: ${this.contact.phoneNumber}\r\n\r\nCompany: ${this.contact.company}\r\n\r\nTitle: ${this.contact.title}\r\n\r\nJob Function: ${this.contact.function}`,
          messageHtml: `<div style="font-size: 16px; font-weight: bold; padding: 0 0 16px 0;">Someone has requested access to PlanGap Insights</div>
          <div style="padding: 0 0 8px 0;"><strong>Name:</strong> ${this.contact.firstName} ${this.contact.lastName}</div>
          <div style="padding: 0 0 8px 0;"><strong>Email:</strong> ${this.contact.email}</div>
          <div style="padding: 0 0 8px 0;"><strong>Phone:</strong> ${this.contact.phoneNumber}</div>
          <div style="padding: 0 0 8px 0;"><strong>Company:</strong> ${this.contact.company}</div>
          <div style="padding: 0 0 8px 0;"><strong>Title:</strong> ${this.contact.title}</div>
          <div style="padding: 0 0 16px 0;"><strong>Job Function:</strong> ${this.contact.function}</div>
          <div><a href="https://us4.admin.mailchimp.com/lists/members/view?id=${mcResultId}">View this contact in MailChimp</a></div>`
        };
        await sendTransactionalEmail(data);
      }
    },
    async handleRequestAccessSubmit() {
      const mergeFields = {
        FNAME: this.contact.firstName,
        LNAME: this.contact.lastName,
        COMPANY: this.contact.company,
        PHONE: this.contact.phoneNumber,
        TITLE: this.contact.title,
        FUNCTION: this.contact.function,
        SOURCE: "InsightsAccessRequest"
      };

      // Get the MC list interests so we can subsrice user to all
      const interestsObj = await interestsFromInterestCategoryMailchimp();
      let assignedInterests = {};

      // Assign new user to all of the MC interest groups by default
      // Wait for all to map before moving to addMailchimp below so all
      // interests get set to true first
      await Promise.all(
        interestsObj.interests.map(async interest => {
          assignedInterests[interest.id] = true;
        })
      );

      const mcresult = await addMailchimp({
        email: this.contact.email,
        mergeFields: mergeFields,
        interests: assignedInterests
      });

      // console.log("MC Add New Member Result", mcresult);

      if (mcresult.error) {
        const errorMsg = JSON.parse(mcresult.error.response.text);
        if (errorMsg.title === "Member Exists") {
          this.showAlert = true;
          (this.alertType = "error"),
            (this.alertMessage =
              "A record has already been found with this email address. Please login if you have an account or contact us for help.");
        }
        if (errorMsg.title === "Forgotten Email Not Subscribed") {
          this.showAlert = true;
          (this.alertType = "error"),
            (this.alertMessage =
              "Sorry, it looks like you may have previously removed your email from our list. Please contact us for assistance or refresh and request access with a different email address.");
        }
      } else {
        this.showAlert = true;
        (this.alertType = "info"),
          (this.alertMessage =
            "Your access request has been sent! We'll be in touch soon with more information.");

        // Send PG an email notification that a user has successfully requested access
        this.sendEmail(mcresult.web_id);
      }

      this.contact = new initialFormData();
    }
  }
};
</script>

<style lang="scss"></style>
